<template>
  <div id="deviceRetirement">
    <el-dialog
      :title="deviceRetirementFormTitle"
      width="680px"
      :visible.sync="deviceRetirementDialogVisible"
      :close-on-click-modal="false"
      @close="deviceRetirementDialogClose"
    >
      <el-form
        ref="deviceRetirementFormRef"
        :model="deviceRetirementForm"
        :rules="deviceRetirementFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="厂内编号" prop="factoryNo">
              <el-select
                v-model="deviceRetirementForm.factoryNo"
                placeholder="请选择设备"
                clearable
                :disabled="deviceRetirementFormTitle !== '新增设备报废申请单'
                  && deviceRetirementFormTitle !== '修改设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
                @change="devChange"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.deviceNo"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input
                v-model="deviceRetirementForm.deviceName"
                placeholder="请输入设备名称"
                clearable
                :disabled="deviceRetirementFormTitle !== '新增设备报废申请单'
                  && deviceRetirementFormTitle !== '修改设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="deviceRetirementForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="deviceRetirementFormTitle !== '新增设备报废申请单'
                  && deviceRetirementFormTitle !== '修改设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出厂编号" prop="outNo">
              <el-input
                v-model="deviceRetirementForm.outNo"
                placeholder="请输入出厂编号"
                clearable
                :disabled="deviceRetirementFormTitle !== '新增设备报废申请单'
                  && deviceRetirementFormTitle !== '修改设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer">
              <el-input
                v-model="deviceRetirementForm.manufacturer"
                placeholder="请输入生产厂家"
                clearable
                :disabled="deviceRetirementFormTitle !== '新增设备报废申请单'
                  && deviceRetirementFormTitle !== '修改设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用年限" prop="useLife">
              <el-input
                v-model="deviceRetirementForm.useLife"
                placeholder="请输入使用年限"
                clearable
                :disabled="deviceRetirementFormTitle !== '新增设备报废申请单'
                  && deviceRetirementFormTitle !== '修改设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="其他信息说明" prop="otherInfo">
              <el-input
                v-model="deviceRetirementForm.otherInfo"
                placeholder="请输入其他信息说明"
                type="textarea"
                :rows="3"
                :disabled="deviceRetirementFormTitle !== '新增设备报废申请单'
                  && deviceRetirementFormTitle !== '修改设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="详细报废原因" prop="reason">
              <el-input
                v-model="deviceRetirementForm.reason"
                placeholder="请输入详细报废原因"
                type="textarea"
                :rows="3"
                :disabled="deviceRetirementFormTitle !== '新增设备报废申请单'
                  && deviceRetirementFormTitle !== '修改设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approverResult">
              <el-radio-group
                v-model="deviceRetirementForm.approverResult"
                :disabled="deviceRetirementFormTitle !== '批准设备报废申请单'
                  && deviceRetirementFormTitle !== '设备报废申请单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="deviceRetirementDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deviceRetirementFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['DEVICE_RETIREMENT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="deviceRetirementPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="outNo" label="出厂编号" />
      <el-table-column prop="factoryNo" label="厂内编号" />
      <el-table-column prop="manufacturer" label="生产厂家" />
      <el-table-column prop="useLife" label="使用年限" />
      <el-table-column prop="otherInfo" label="其他信息说明" />
      <el-table-column prop="reason" label="详细报废原因" />
      <el-table-column prop="applicant" label="申请人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applicantDate">{{ scope.row.applicantDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approverDate">{{ scope.row.approverDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approverResult === 1">通过</span>
          <span v-if="scope.row.approverResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['DEVICE_RETIREMENT_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['DEVICE_RETIREMENT_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['DEVICE_RETIREMENT_APPROVAL']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="deviceRetirementPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDeviceRetirement,
  deleteDeviceRetirement,
  updateDeviceRetirement,
  selectDeviceRetirementInfo,
  selectDeviceRetirementList,
  approvalDeviceRetirement
} from '@/api/universal/deviceRetirement'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      deviceRetirementDialogVisible: false,
      deviceRetirementFormTitle: '',
      deviceRetirementForm: {
        id: '',
        deviceName: '',
        spec: '',
        outNo: '',
        factoryNo: '',
        manufacturer: '',
        useLife: '',
        otherInfo: '',
        reason: '',
        approverResult: '',
        taskId: ''
      },
      deviceRetirementFormRules: {
        deviceName: [{ required: true, message: '设备名称不能为空', trigger: ['blur', 'change']}]
      },
      deviceRetirementPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      },
      devList: []
    }
  },
  created () {
    selectDeviceRetirementList(this.searchForm).then(res => {
      this.deviceRetirementPage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    deviceRetirementDialogClose () {
      this.$refs.deviceRetirementFormRef.resetFields()
    },
    deviceRetirementFormSubmit () {
      if (this.deviceRetirementFormTitle === '设备报废申请单详情') {
        this.deviceRetirementDialogVisible = false
        return
      }
      this.$refs.deviceRetirementFormRef.validate(async valid => {
        if (valid) {
          if (this.deviceRetirementFormTitle === '新增设备报废申请单') {
            await addDeviceRetirement(this.deviceRetirementForm)
          } else if (this.deviceRetirementFormTitle === '修改设备报废申请单') {
            await updateDeviceRetirement(this.deviceRetirementForm)
          } else if (this.deviceRetirementFormTitle === '批准设备报废申请单') {
            await approvalDeviceRetirement(this.deviceRetirementForm)
          }
          this.deviceRetirementPage = await selectDeviceRetirementList(this.searchForm)
          this.deviceRetirementDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.deviceRetirementFormTitle = '新增设备报废申请单'
      this.deviceRetirementDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDeviceRetirement(row.id)
        if (this.deviceRetirementPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.deviceRetirementPage = await selectDeviceRetirementList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.deviceRetirementFormTitle = '修改设备报废申请单'
      this.deviceRetirementDialogVisible = true
      this.selectDeviceRetirementInfoById(row.id)
    },
    handleApproval (index, row) {
      this.deviceRetirementFormTitle = '批准设备报废申请单'
      this.deviceRetirementDialogVisible = true
      this.selectDeviceRetirementInfoById(row.id)
    },
    handleInfo (index, row) {
      this.deviceRetirementFormTitle = '设备报废申请单详情'
      this.deviceRetirementDialogVisible = true
      this.selectDeviceRetirementInfoById(row.id)
    },
    selectDeviceRetirementInfoById (id) {
      selectDeviceRetirementInfo(id).then(res => {
        this.deviceRetirementForm.id = res.id
        this.deviceRetirementForm.deviceName = res.deviceName
        this.deviceRetirementForm.spec = res.spec
        this.deviceRetirementForm.outNo = res.outNo
        this.deviceRetirementForm.factoryNo = res.factoryNo
        this.deviceRetirementForm.manufacturer = res.manufacturer
        this.deviceRetirementForm.useLife = res.useLife
        this.deviceRetirementForm.otherInfo = res.otherInfo
        this.deviceRetirementForm.reason = res.reason
        this.deviceRetirementForm.approverResult = res.approverResult
        this.deviceRetirementForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDeviceRetirementList(this.searchForm).then(res => {
        this.deviceRetirementPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDeviceRetirementList(this.searchForm).then(res => {
        this.deviceRetirementPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDeviceRetirementList(this.searchForm).then(res => {
        this.deviceRetirementPage = res
      })
    },
    devChange (value) {
      if (value) {
        let dev = this.devList.find(item => item.deviceNo === value)
        this.deviceRetirementForm.deviceName = dev.deviceName
        this.deviceRetirementForm.spec = dev.spec
        this.deviceRetirementForm.outNo = dev.serialNumber
        this.deviceRetirementForm.manufacturer = dev.manufacturer
      } else {
        this.deviceRetirementForm.deviceName = ''
        this.deviceRetirementForm.spec = ''
        this.deviceRetirementForm.outNo = ''
        this.deviceRetirementForm.manufacturer = ''
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['DEVICE_RETIREMENT_APPROVAL']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>

import axios from '@/common/axios'
import qs from 'qs'

export function addDeviceRetirement (data) {
  return axios({
    method: 'post',
    url: '/universal/deviceRetirement/add',
    data: qs.stringify(data)
  })
}

export function deleteDeviceRetirement (id) {
  return axios({
    method: 'delete',
    url: '/universal/deviceRetirement/delete/' + id
  })
}

export function updateDeviceRetirement (data) {
  return axios({
    method: 'put',
    url: '/universal/deviceRetirement/update',
    data: qs.stringify(data)
  })
}

export function selectDeviceRetirementInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/deviceRetirement/info/' + id
  })
}

export function selectDeviceRetirementList (query) {
  return axios({
    method: 'get',
    url: '/universal/deviceRetirement/list',
    params: query
  })
}

export function approvalDeviceRetirement (data) {
  return axios({
    method: 'put',
    url: '/universal/deviceRetirement/approval',
    data: qs.stringify(data)
  })
}
